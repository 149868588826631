/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { getLaunchpad,getLaunchpadCms } from '../../actions/launchpadActions'
// import  AdPopup from "./AdPopup"; 
import store from "../../store";
import isLogin from "../../lib/isLogin";
import { getAuthToken } from "../../lib/localStorage";
import styles from "assets/jss/material-kit-react/components/footerStyle.js";

// import action
import { getLanguage,getSiteSetting } from '../../actions/commonAction';

// import lib
import { capitalize } from '../../lib/stringCase';
import { setLang, getLang } from '../../lib/localStorage';
import isEmpty from "../../lib/isEmpty";
import $ from "jquery"

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  // state
  const [langOption, setLangOption] = useState([])
  const [language, setLanguage] = useState('')
  const [setting, setSetting] = useState('')
  const [advflag, setAdvflag] = useState('false')

  const { isAuth } = store.getState().auth;

  // function
  const handleLanguage = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setLanguage(value)
    setLang(value)
    i18n.changeLanguage(value);
  }
  const settingData = async() =>{
    try {
      const { status, loading, result } = await getSiteSetting(dispatch);
      
      if (status == 'success') {
        setSetting(result);
      }
    } catch (err) { }
  }
  const fetchLanguage = async () => {
    try {
      const { status, loading, result } = await getLanguage(dispatch);
      if (status == 'success') {
        setLangOption(result);
        let lang = getLang();
        if (isEmpty(lang)) {
          let primaryData = result && result.length > 0 && result.find((el => el.isPrimary == true))
          if (primaryData) {
            setLanguage(primaryData.code)
            setLang(primaryData.code)
            i18n.changeLanguage(primaryData.code);
          }
        } else {
          setLanguage(lang)
        }
      }
    } catch (err) { }
  }

  useEffect(() => {
    fetchLanguage();
    settingData();

    // if (isAuth != true && isLogin()) {
    // } else {
    // // window.$('#exampleModalCenter').modal('show') // to show 
    
    // }

  }, [])


  return (
    <footer className="pt-4">
      <div className="container">
        <div className="row pb-lg-3">
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Company</h5>
            <ul className="list-inline">
              <li><Link to="/about">About Us</Link></li>
              <li><Link to="/press">Press</Link></li>
              <li><Link to="/investors">Investors</Link></li>
              <li><Link to="/terms">Terms of Service</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Investing & Trading</h5>
            <ul className="list-inline">
              {/* <li><Link to="">Business accounts</Link></li> */}
              {/* <li><Link to="/blog">Blog</Link></li> */}
              {/* <li><Link to="/api/documentation/v1">API</Link></li> */}
              {/* <li><Link to="/fee">Fee Schedule</Link></li> */}
              {/* <li><Link to="">OTC / P2P trading</Link></li> */}
              <li><Link to="/settings">Security</Link></li>
              <li><Link to="/staking">Staking</Link></li>
              <li><Link to="/launchpad">Launchpad</Link></li>
              <li><Link to="/p2p">P2P</Link></li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Support</h5>
            <ul className="list-inline">
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/support-ticket">Help Center</Link></li>
              <li><Link to="/contact">Contact us</Link></li>
            </ul>
          </div>
          <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <h5>Follow With Us On</h5>
              <ul className="list-inline d-flex social_media">
                <li className="pr-4"><a href={setting && setting.youtubelink && setting.facebookLink} target="_blank"><i className="fab fa-facebook"></i></a></li>
                <li className="pr-4"><a href={setting && setting.telegramlink && setting.telegramlink} target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
                <li className="pr-4"><a href={setting && setting.twitterUrl && setting.twitterUrl} target="_blank"><i className="fab fa-twitter"></i></a></li>
                <li className="pr-4"><a href={setting && setting.linkedinLink && setting.linkedinLink} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                <li className="pr-4"><a href={setting && setting.redditlink && setting.redditlink} target="_blank"><i className="fab fa-reddit-alien"></i></a></li>
                {/* <li className="pr-4"><a href={setting && setting.youtubelink && setting.youtubelink} target="_blank"><i className="fab fa-youtube"></i></a></li> */}
              </ul>
          </div>
          {/* <div className="col-sm-6 col-lg-3" data-aos="fade-up">
            <div className="footer_menu">
              <select
                className="custom-select"
                name="language"
                value={language}
                onChange={handleLanguage}
              >
                {
                  langOption && langOption.length > 0 && langOption.map((item, key) => {
                    return (
                      <option key={key} value={item.code}>{capitalize(item.name)}</option>
                    )
                  })
                }
              </select>
            </div>
            
          </div> */}

        </div>
        <div className="footer_bottom text-center">
          <div className="row py-3">
            <div className="col-md-12 mb-md-0">            
              <p>Copyright © 2022<span>Deep Liquidity,</span> All rights reserved</p>
            </div>            
          </div>
        </div>
        


      </div>
     {/* <AdPopup/> */}
         </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
