let key = {};

if (process.env.REACT_APP_MODE === "production") {
   console.log = function () {};

  console.log("Set Production Config");
  const API_URL = "https://api.deepliquidity.exchange";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    RECAPTCHA_SITE_KEY: "6Lcp_8giAAAAAPvHsTcP1aKSBHGNp5cFkEoJG8Wr", //Deepliqudity
    API_URL: `${API_URL}`,
    FRONT_URL: "https://www.deepliquidity.exchange",
    // API_URL: "http://54.151.2.168:2053",
    // FRONT_URL: "http://54.151.2.168",
    ADMIN_URL: "https://controls.deepliquidity.exchange",
    SOCKET_URL: API_URL,

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/deepliquidity",
      twitterUrl: "https://www.twitter.com/deepliquidity",
      linkedinUrl: "https://www.linkedin.com/deepliquidity",
      redditUrl: "https://www.reddit.com/deepliquidity",
      youtubeUrl: "https://www.youtube.com/deepliquidity",
    },
    exchangeurl: "https://ico.deepliquidity.exchange/home/",
    // zaakpayurl:"https://api.zaakpay.com"
    // 'https://ico.mudra.exchange',
    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
    // zaakpayurl:"https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "",
    returnUrl: "https://api.mudra.exchange/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "",
    ON_RAMP : {
      API_KEY : 'pk_test_BS5Zekl_b9YHm9MBvwkads1b2XKESvgM6X3jJR8gTNo0'
    }
  };


}else if (process.env.REACT_APP_MODE === "demo") {
  console.log("Set demo Config");
  const API_URL = "https://test.deepliquidity.exchange";

  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3",
    RECAPTCHA_SITE_KEY: "6Lc2c3QiAAAAAAg3pGUo3p-VlabViOKhxl4jJgTS", //MUDRAEX
    API_URL: `${API_URL}`,
    FRONT_URL: "https://test.deepliquidity.exchange",
    // API_URL: "http://54.151.2.168:2053",
    // FRONT_URL: "http://54.151.2.168",
    ADMIN_URL: "https://test.deepliquidity.exchange/admin",
    SOCKET_URL: API_URL,

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/deepliquidity",
      twitterUrl: "https://www.twitter.com/deepliquidity",
      linkedinUrl: "https://www.linkedin.com/deepliquidity",
      redditUrl: "https://www.reddit.com/deepliquidity",
      youtubeUrl: "https://www.youtube.com/deepliquidity",
    },
    exchangeurl: "https://ico.deepliquidity.exchange/home/",
    // zaakpayurl:"https://api.zaakpay.com"
    // 'https://ico.mudra.exchange',
    zaakpayurl: "https://api.zaakpay.com/api/paymentTransact/V7",
    // zaakpayurl:"https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "",
    returnUrl: "https://api.mudra.exchange/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "",
    ON_RAMP : {
      // API_KEY : 'pk_test_BS5Zekl_b9YHm9MBvwkads1b2XKESvgM6X3jJR8gTNo0' // TESTNET
      API_KEY : 'pk_prod_xrzHfTE3vHP8LjYKqoSLEjbn81DWRjsn0SN4r4Gy6Sw0' // LIVE
    }
  };
 
} else {

  console.log("Set Development Config");
  const API_URL = "http://localhost"; //http://192.168.29.63:3000/  http://localhost
  key = {
    secretOrKey: "FxUum76z",
    CRYPTO_SECRET_KEY: "1234567812345678",
    RECAPTCHA_SITE_KEY: "6LfwpSkhAAAAAM6v9L0cDPBNsDCFTVbP4c0Ml0jJ",
    API_URL: `${API_URL}:2053`,
    SOCKET_URL: `${API_URL}:2053`,
    FRONT_URL: "http://localhost:3000",

    getGeoInfo: "https://ipapi.co/json/",
    AUTHENTICATOR_URL: {
      PLAY_STORE:
        "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
      APP_STORE:
        "https://apps.apple.com/us/app/google-authenticator/id388497605",
    },
    socialMedia: {
      telegramUrl: "https://telegram.org/mudra",
      twitterUrl: "https://www.twitter.com/mudra",
      linkedinUrl: "https://www.linkedin.com/mudra",
      redditUrl: "https://www.reddit.com/mudra",
      youtubeUrl: "https://www.youtube.com/mudra",
    },
    exchangeurl: "http://localhost:3002/home/",
    zaakpayurl: "https://zaakstaging.zaakpay.com/api/paymentTransact/V8",
    MerchantID: "fb2016ffd3a64b2994a6289dc2b671a4",
    returnUrl: "http://localhost:2053/api/zaakpay/zaakpayconfirmed",
    zaaksecretkey: "0678056d96914a8583fb518caf42828a",
    ON_RAMP : {
      API_KEY : 'pk_test_BS5Zekl_b9YHm9MBvwkads1b2XKESvgM6X3jJR8gTNo0'
    }
  };
}

export default key;

// 6Lcz48IbAAAAAJffeGirGcjkr3PIKO4sZxBaf66G - secret key

// 6Lcz48IbAAAAACTRIVYhc9NZM1ZE8jAt0sTFUGEi - site key
