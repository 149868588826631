// import config
import axios, { handleResp } from '../config/axios'

export const getLaunchpad = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/get-launchpad`,
        });

        return {
            'status': respData.data.status,
            'launchpadActive': respData.data.launchpadActive,
            'launchpadComplete': respData.data.launchpadComplete
        }

    }
    catch (err) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': err.response.data.message,
            'error': err.response.data.errors
        }
    }
}

export const updtaeLaunchpad = async () => {
    try {
        let respData = await axios({
            'method': 'put',
            // 'url': `/api/get-launchpad`,
        });

        return {
            'status': true,
            message: respData.data.message
        }

    }
    catch (err) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': err.response.data.message,
            'error': err.response.data.errors
        }
    }
}
export const getLaunchpadCms = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/get-launchpadcms`,
        });
        return {
            'status': respData.data.success,
            'launchpadCMS': respData.data.result,

        }

    }
    catch (err) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': err.response.data.message,
            'error': err.response.data.errors
        }
    }
}


export const getSingleLaunchpad = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/get-single-launchpad/${id}`,
        });

        return {
            'status': respData.data.status,
            'result': respData.data.data,
        }

    }
    catch (err) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': err.response.data.message,
            'error': err.response.data.errors
        }
    }
}

export const createLaunchpadOrder = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `/api/create-launchpad-order`,
            data
        });

        return {
            'status': respData.data.status,
            'message': respData.data.message
        }

    }
    catch (err) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': err.response.data.message,
            'error': err.response.data.errors
        }
    }
}

export const getLaunchpadHistory = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `/api/get-launchpad-orders/${id}`,
        });

        return {
            'status': respData.data.status,
            'result': respData.data.result
        }

    }
    catch (err) {
        handleResp(err, 'error')
        return {
            'status': false,
            'message': err.response.data.message,
            'error': err.response.data.errors
        }
    }
}

