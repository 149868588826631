// import lib
import isEmpty from '../../lib/isEmpty';

export const validation = value => {

    let errors = {};
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;

        if (value.userId=="") {
            errors.userId = "REQUIRED"
        }

        if (value.currencyselected == "") {
            errors.currencyselected = "Please Select a Cyprocurreny would like to trade";
        }
        if (value.posttocurrency == "") {
            errors.posttocurrency = "Please Select a local currency";
        } 
        if (value.minlimit == "") {
            errors.postminlimit = "Please Enter the Min Limit";
        } else if (isNaN(value.minlimit) == true) {
            errors.postminlimit = "Please Enter the Min Limit number only";
        }  
        if (value.maxlimit == "") {
            errors.postmaxlimit = "Please Enter the Max Limit";
        }else if (isNaN(value.maxlimit) == true) {
            errors.postmaxlimit = "Please Enter the Max Limit number only";
        }
        if (value.quantity == "") {
            errors.quantity = "Please Enter the Quantity ";
        } else if (isNaN(value.quantity) == true) {
            errors.quantity = "Please Enter the Quantity as number only";
        } 
        if (value.minlimit != "" && value.maxlimit != "") {
            if (
            parseFloat(value.minlimit) >
            parseFloat(value.maxlimit)
            ) {
                errors.postminlimit = "Please Enter the correct Minimum Value";
            }
        }
        if (value.postprefcurrency=="") {
            errors.postprefcurrency = "Please Select Preferred Payments";
        }
        if (value.postcheckboxaccept!= true){
            errors.postcheckboxaccept = "Please accept the terms and policy";
        }

    // if (isEmpty(value.email)) {
    //     errors.email = "REQUIRED"
    // } else if (!(emailRegex.test(value.email))) {
    //     errors.email = "EMAIL_INVALID"
    // }

    // if (isEmpty(value.password)) {
    //     errors.password = "REQUIRED"
    // }

    // if (!(value.isTerms == true)) {
    //     errors.isTerms = "REQUIRED"
    // }

    return errors;
}

// export default validation;

export const UpdatePostValidation = value => {

    let errors = {};

    if (value.minlimit == "") {
        errors.postminlimit = "Please Enter the Min Limit";
    } else if (isNaN(value.minlimit) == true) {
        errors.postminlimit = "Please Enter the Min Limit number only";
    } else if (value.minlimit <= 0) {
        errors.postminlimit = "Please Enter the Min Limit greater than zero";
    }  


    if (value.maxlimit == "") {
        errors.postmaxlimit = "Please Enter the Max Limit";
    }else if (isNaN(value.maxlimit) == true) {
        errors.postmaxlimit = "Please Enter the Max Limit number only";
    }

    if (value.quantity == "") {
        errors.quantity = "Please Enter the Quantity ";
    } else if (isNaN(value.quantity) == true) {
        errors.quantity = "Please Enter the Quantity as number only";
    } 

    if (value.minlimit != "" && value.maxlimit != "") {
        if (
        parseFloat(value.minlimit) >
        parseFloat(value.maxlimit)
        ) {
            errors.postminlimit = "Please Enter the correct Minimum Value";
        }
    }
    if (value.postprefcurrency=="") {
        errors.postprefcurrency = "Please Select Preferred Payments";
    }


    return errors;
}