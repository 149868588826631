// Authentication
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const REMOVE_AUTHENTICATION = "REMOVE_AUTHENTICATION";

// Modal
export const OPEN_PHONE_NO_MODAL = "OPEN_PHONE_NO_MODAL";
export const CLOSE_PHONE_NO_MODAL = "CLOSE_PHONE_NO_MODAL";
export const OPEN_EMAIL_MODAL = "OPEN_EMAIL_MODAL";
export const CLOSE_EMAIL_MODAL = "CLOSE_EMAIL_MODAL";

// Current User
export const SET_BANK_DETAIL = "SET_BANK_DETAIL";
export const SET_BANK_FORM = "SET_BANK_FORM";
export const SET_UPI_DETAIL = "SET_UPI_DETAIL";
export const SET_UPI_FORM = "SET_UPI_FORM";
export const SET_QR_DETAIL = "SET_QR_DETAIL";
export const SET_QR_FORM = "SET_QR_FORM";
// Common
export const SET_LANGUAGE_OPTION = "SET_LANGUAGE_OPTION";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_TIME_ZONE = "SET_TIME_ZONE";
export const SET_USER_KYC = "SET_USER_KYC";
export const SET_ID_PROOF_KYC = "SET_ID_PROOF_KYC";
export const SET_ADDRESS_PROOF_KYC = "SET_ADDRESS_PROOF_KYC";
export const SET_USER_SETTING = "SET_USER_SETTING";
export const SET_THEME = "SET_THEME";
export const SET_TRADE_THEME = "SET_TRADE_THEME";
export const SET_PRICE_CONVERSION = "SET_PRICE_CONVERSION";
export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";

// Spot Trade
export const SET_LAST_PRICE = "SET_LAST_PRICE";
export const SET_SPOT_PAIR_TRADE = "SET_SPOT_PAIR_TRADE";
export const SET_SPOT_TRADE_PAIR_LIST = "SET_SPOT_TRADE_PAIR_LIST";
export const SET_SPOT_MARKET_PRICE = "SET_SPOT_MARKET_PRICE";
export const UPDATE_SPOT_ASSET = "UPDATE_SPOT_ASSET";
export const SET_SPOT_ORDER_BOOK = "SET_SPOT_ORDER_BOOK";

// Perpetual Trade
export const SET_PERPETUAL_MARKET_PRICE = "SET_PERPETUAL_MARKET_PRICE";
export const SET_PERPETUAL_PAIR_TRADE = "SET_PERPETUAL_PAIR_TRADE";
export const SET_PERPETUAL_ORDER_BOOK = "SET_PERPETUAL_ORDER_BOOK";

// Currency
export const SET_USER_FIRST_CURRENCY = "SET_USER_FIRST_CURRENCY";
export const SET_USER_SECOND_CURRENCY = "SET_USER_SECOND_CURRENCY";

// Wallet
export const SET_USER_WALLET_LIST = "SET_USER_WALLET_LIST";
export const UPDATE_USER_WALLET_STAKE = "UPDATE_USER_WALLET_STAKE";

// Stake Order
export const STAKE_ORDER_LIST_SUCCESS = "STAKE_ORDER_LIST_SUCCESS";
export const STAKE_ORDER_LIST_ERROR = "STAKE_ORDER_LIST_ERROR";
export const ADD_NEW_STAKE_ORDER = "ADD_NEW_STAKE_ORDER";
export const CANCEL_STAKE_ORDER = "CANCEL_STAKE_ORDER";

// Account
export const SET_USER_ACCOUNT = "SET_USER_ACCOUNT";
export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";

// Form
export const SET_INITIAL_FORM = "SET_INITIAL_FORM";
export const UPDATE_INITIAL_FORM = "UPDATE_INITIAL_FORM";
export const CHANGE_FORM_VALUE = "CHANGE_FORM_VALUE";
export const FORM_VALIDATION_ERROR = "FORM_VALIDATION_ERROR";

export const SET_TOKEN_FEE = "SET_TOKEN_FEE";
